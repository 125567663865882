import React from 'react';
import {
    makeStyles,
    FormControlLabel,
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Fab,
    Button,
} from '@material-ui/core';
import s from './Profile.scss';
import { useIntl } from 'gatsby-plugin-intl';
import Switch from '@material-ui/core/Switch';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';



const useStyles = makeStyles((theme) => ({
    card: {
        width: 840,
        marginTop: 20,
    },
    align: {
        marginTop: 20,
        float: "left"
    },
    header: {
        fontWeight: 900
    },
    editIcon: {
        height: 2,
        width: 29
    },
    profileIcon: {
        height: 100,
        width: 74
    }
    
}));

const state = ({
    checkedA: true,
    checkedB: true,
});

const fields = [
    { key: 'Email ID', value: 'Jagadesh@gmail.com' },
    { key: 'Mobile', value: '999999999' },
    { key: 'User Type', value: 'Researcher' },
];

function Profile() {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <Container>
          
            <Grid container direction="row"  >
                <Grid item xs={1}>
                <AccountCircle className={classes.profileIcon} />
                </Grid>
                <Grid item xs={3} className={classes.align}>
                <Typography>Welcome</Typography>
                <Typography>JAGADESH</Typography>
                </Grid>
                <Grid item xs={3} className={classes.align}>
                    <Button className={s.button}>
                     Change Password 
              </Button>
               </Grid>
            </Grid>

            <Card className={classes.card}>
                <CardContent className={s.footer}>
                    <Grid container direction="row">
                        <Grid item xs={3} className={s.account}>
                            <Typography
                                className={classes.header}>
                                Account Information
                            </Typography>
                        </Grid>
                        <Grid item xs={3} >
                        <Fab color="secondary" aria-label="edit" className={classes.editIcon}>
        <EditIcon />
      </Fab>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.align}>
                        <Grid item xs={3}>
                        <Typography> Email Id </Typography>
           </Grid>
                        <Grid item xs={3}>
                        <Typography> Jagdesh@gmail.com </Typography>
           </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.align} >
                        <Grid item xs={3}>
                        <Typography>Mobile</Typography>    
           </Grid>
                        <Grid item xs={3}>
                        <Typography> 9999999999 </Typography>
           </Grid>
                    </Grid>
                    <Grid container direction="row" className={classes.align}>
                        <Grid item xs={3}>
                        <Typography> UserType </Typography>
           </Grid>
                        <Grid item xs={3}>
                        <Typography>   Researcher </Typography>
           </Grid>
                    </Grid>

                    {/* <Grid container direction="row" className={classes.align}>
                        {fields.map((fields, i) => (
                            <Grid item xs={3} 
                            className={classes.card}>
                               {intl.formatMessage({ id: fields.key })}
                                {intl.formatMessage({ id: fields.value })}
                                <br />
                            </Grid>
                        ))}
                    </Grid> */}

                 





                </CardContent>
            </Card>

            <Card className={classes.card}>

                <CardContent>
                    <Grid container direction="row" className={classes.align}>
                        <Grid item xs={3}>

                            <Typography
                                className={classes.header}>
                                Notifications Preference
                            </Typography>


                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.align}>
                        <Grid item xs={3}>
                        <Typography>  Email Notification  </Typography>  
           </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch checked={state.checkedA} name="checkedA" />}
                                label=""
                            />
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container direction="row" className={classes.align}>
                        <Grid item xs={3}>
                        <Typography>   News Letter </Typography>
           </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch checked={state.checkedA} name="checkedA" />}
                                label=""
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


        </Container>

    )

}

export default Profile;

